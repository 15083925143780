export * from './core/alert.service';
export * from './core/aws-uploader.service';
export * from './core/loading.service';
export * from './core/media-recorder.service';
export * from './core/media-stream.service';
export * from './core/event-subscription.service';
export * from './core/redirection.service';
export * from './core/audio-score-processor.service';
export * from './core/video-score-processor.service';
export * from './core/volume-provider.service';
export * from './core/watson.service';
export * from './core/socket.service';


export * from './data/api.service';
export * from './data/auth.service';
export * from './data/content.service';
export * from './data/user.service';
export * from './data/pings.service';
export * from './data/tip-provider.service';
export * from './data/result.service';
export * from './data/word-management.service';
export * from './data/customer.service';
